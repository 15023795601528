<template>
  <div class="row mt-3 align-self-center">
    <div class="col-6">
      <div class="title">Pembayaran Project</div>
      <div class="sub-title">Edit informasi yang diperlukan</div>
    </div>
    <div class="col-6 text-right">
      <div
        class="btn btn-add d-inline-block"
        style="cursor: pointer"
        @click="createInvoice"
        v-if="isEditable"
      >
        Buat Invoice
      </div>
    </div>
  </div>
  <hr />
  <div class="table-responsive mt-4">
    <table
      class="table table-bordered table-hover table-striped"
      id="tablePayment"
    >
      <thead>
        <tr>
          <th style="width: 15%">Nomor Invoice</th>
          <th style="width: 20%">Tanggal</th>
          <th style="width: 20%">Jatuh Tempo</th>
          <th style="width: 15%">Sub Total</th>
          <th style="width: 15%">Pajak</th>
          <th style="width: 20%">Total</th>
          <th style="width: 5%">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(value, index) in invoiceList"
          :key="index"
          v-on:click="detail(value.no_invoice)"
          data-toggle="tooltip"
          data-placement="top"
          title="Klik untuk melihat detail"
        >
          <td>{{ value.no_invoice }}</td>
          <td>{{ formatDate(value.tanggal) }}</td>
          <td>{{ formatDate(value.due_date) }}</td>
          <td class="text-right">{{ formatMoney(value.sub_total) }}</td>
          <td class="text-right">{{ formatMoney(value.nilai_pajak) }}</td>
          <td class="text-right">{{ formatMoney(value.nilai) }}</td>
          <td>
            <div class="green-bedge" v-if="value.status_pembayaran == 'paid'">
              Paid
            </div>
            <div class="red-bedge text-capitalize" v-else>
              {{ value.status_pembayaran }}
            </div>
          </td>
        </tr>
        <tr v-if="invoiceList.length === 0">
          <td colspan="7" class="text-center">Tidak ada data</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import moment from "moment";
import "moment/locale/id";
export default {
  name: "DetailPayment",
  props: {
    list: Object,
    id_piutang: String,
    kode_project: String,
    isEdit: Boolean,
  },

  data() {
    return {
      id: this.$props.id_piutang,
      kode: this.$props.kode_project,
      invoiceList: this.$props.list,
      isEditable: this.$props.isEdit,
    };
  },

  // created() {
  //   console.log(this.$props.isEdit);
  // },

  methods: {
    createInvoice() {
      this.$router.push({
        name: "InvoiceProject",
        params: {
          kode_project: this.kode,
        },
      });
    },
    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDate(string) {
      return moment(string).format("DD MMMM YYYY");
    },
    detail(id) {
      this.$emit("show", id);
    },
  },

  watch: {
    kode_project(newVal) {
      this.kode = newVal;
    },
    list(newVal) {
      this.invoiceList = newVal;
    },
    id_piutang(newVal) {
      this.id = newVal;
    },
    isEdit(newVal) {
      this.isEditable = newVal;
    },
  },
};
</script>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>

<style scoped>
.btn-add-title {
  background-color: #d7ffea;
  color: #3cb778;
  font-weight: 500;
}

.btn-add {
  background-color: #3cb778;
  color: #fff !important;
  padding: 7px 12px;
  font-weight: 400 !important;
  border-radius: 5px;
  text-decoration: none !important;
  font-size: 15px;
  border: none;
}

.modal table {
  border-right: none;
  border-left: none;
}

.modal .table td {
  padding: 0 25px !important;
  vertical-align: middle;
  font-size: 14px;
  border: none !important;
}

.modal .table th {
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #eceff1 !important;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #eceff1;
}

.table td,
.table th {
  padding: 10px 13px !important;
  vertical-align: middle;
  font-size: 14px;
  border-top: 1px solid #dee2e6;
}

.btn-add-row {
  color: #f8ce3a;
  background-color: #fff3c7;
  font-weight: 500;
}

.title-item {
  background-color: #eceff1;
}

.title-rab {
  background-color: #2e3a59;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.btn-save {
  background-color: #40ddd4;
  color: #fff;
  /* width: 90px; */
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0px;
}

.btn-add {
  padding: 10px 10px;
  margin-left: 20px;
}

.nav-pills .nav-item .nav-link.active[data-v-161fae0a]:after {
  border-radius: 5px !important;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.modal-dialog {
  max-width: 800px;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

.modal-content {
  overflow-y: auto;
  height: 100%;
  border-radius: 0px;
}

/* detail invoice */
.btn-lampiran {
  background-color: #f9ffd7;
  color: #b9b201;
  font-weight: 400;
}

#modalAddPayment .btn-save {
  background-color: transparent !important;
  color: #68caf8 !important;
  border: 1px solid #68caf8 !important;
  font-weight: 400;
  font-size: 15px;
  padding: 10px 0px;
}

#modalAddPayment .btn-save:hover {
  background-color: #68caf8 !important;
  color: #fff !important;
}

.card-detail-invoice {
  background: linear-gradient(103.2deg, #6ec0fe 0%, #4df3dd 100%);
  border-radius: 5px;
  color: #fff;
  padding: 10px 20px;
  line-height: 30px;
}

#modalPrintInvoice .modal-dialog {
  max-width: 1162px;
}

.modal .table td {
  height: 44px !important;
}

.btn-add-payment {
  background-color: #d7ffea;
  color: #3cb778;
  padding: 5px 10px;
  font-weight: 500;
  margin-right: 15px;
}

.btn-print-invoice {
  background-color: #f9ffd7;
  color: #b9b201;
  padding: 5px 10px;
  font-weight: 500;
}

.address-company {
  font-size: 12px;
  color: #263238;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.noEstimated {
  font-size: 12px;
  color: #898989;
}

.form-label {
  max-width: 134px;
  width: 200px;
}

.card-total {
  display: flex;
}

.payment-invoice {
  padding: 0px 46px;
}

.sub-total {
  margin-right: 50px;
  font-size: 14px;
}

.amount {
  font-size: 14px;
}

.name-company {
  font-size: 18px;
  font-weight: 500;
}

.detail-company .value {
  color: #263238;
  font-size: 16px;
  font-weight: 400;
}

.detail-company .text {
  color: #cdcdcd;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.title-invoice {
  font-weight: 700;
  font-size: 50px;
  background: linear-gradient(102.18deg, #6ec0fe -17.12%, #61efe7 95.5%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.total-payment {
  font-size: 24px;
  font-weight: 600;
  margin-top: 10px;
}

/* .text {
        margin-top: 10px;
    } */

.status-invoice-paid {
  background: #d7ffea;
  color: #3cb778;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 50px;
  border: none;
  cursor: default;
  border-radius: 5px;
}

.btn-export {
  background: #e4efff;
  color: #68caf8;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 10px;
  border-radius: 5px;
  border: none;
}

.modal .btn-print-invoice {
  background: #f9ffd7;
  color: #b9b201;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 23px;
  border-radius: 5px;
  border: none;
}

.btn-invoice {
  background: #fff3c7;
  color: #f8ce3a;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 15px;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}

/* End Detail Invoice */
</style>
