<template>
  <div>
    <iframe id="pdfFrame" v-if="!isLoading"></iframe>
    <div
      v-else
      :style="{
        height: '450px',
        background: '#8f8f8f',
        position: 'relative',
      }"
    >
      <loading
        :style="{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }"
        :active="isLoading"
        :can-cancel="false"
        :on-cancel="onCancel"
        :is-full-page="fullPage"
        color="#68caf8"
        height="80"
        width="80"
      ></loading>
    </div>
  </div>
</template>
    
<script>
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import "../../../../assets/font/poppins-bold.js";
import "../../../../assets/font/poppins-regular.js";

import Loading from "vue3-loading-overlay";

import $ from "jquery";

import moment from "moment";
import "moment/locale/id";

import "@/assets/fonts/poppins/poppins.normal";
import "@/assets/fonts/poppins/poppins.italic";
import "@/assets/fonts/poppins/poppins.bold-italic";
import "@/assets/fonts/poppins/poppins.bold";
//   import { get_ReportDebt } from "../../../../actions/report/summary";
import { DATA } from "../../../../constans/Url";
// import { NONE } from "apisauce";

// import TableLite from 'vue3-table-lite'
export default {
  name: "ExportPDFPe",
  components: {
    Loading,
  },
  props: {
    company: Object,
    client: Object,
    total: Number,
    list: Array,
    pajak: Number,
    managementFee: Number,
    managementFeeSatuan: String,
    project: Object
  },
  data() {
    return {
      showModal: false,
      fullPage: true,
      isLoading: false,
      canvas: null,
      dataCompany: this.$props.company,
      dataHeaderPE: [
        {
            title: "Nama Item",
            dataKey: "nama",
        },
        {
            title: "QTY",
            dataKey: "qty",
        },
        {
            title: "FRQ",
            dataKey: "frq_qty",
        },
        {
            title: "Volume",
            dataKey: "volume",
        },
        {
            title: "Unit Cost",
            dataKey: "unit_cost",
        },
        {
            title: "Total",
            dataKey: "nilai",
        },
      ],
      dataReportPE: [],
      dataNilai: {
        total_pe: `Rp. ${this.formatMoney(this.$props.total)}`,
        total_management: "Rp. 0",
        total_pajak: `Rp. ${this.formatMoney(this.$props.pajak)}`,
        management: this.$props.managementFee,
        satuan: this.$props.managementFeeSatuan,
      },
      dataClient: this.$props.client,
      titleDate: "",
      titleProject: "",
      titleReport: "ITEM PE",
      logo: "",
    };
  },

  mounted() {
    // console.log(cksClient().get("_account"));
    // this.tooltip();
    // this.generatePdf();
    // this.countManagement();
  },

  methods: {
    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    setHide() {
      this.showModal = false;
    },

    // getData() {
    //   var data = {
    //     ...this.parameterReport,
    //   };
    //   data.id_company = cksClient().get("_account").id_company;
    //   this.isLoading = true;
    //   get_ReportDetailProject(
    //     data,
    //     (res) => {
    //       const {
    //         nilai,
    //         client,
    //         header_rab,
    //         report_rab,
    //         header_pe,
    //         report_pe,
    //         header_invoice,
    //         report_invoice,
    //         project,
    //         company,
    //       } = res;
    //       this.dataCompany = company;
    //       this.dataClient = client;
    //       this.dataHeaderRab = header_rab;

    //       for (const key in report_rab) {
    //         if (Object.hasOwnProperty.call(report_rab, key)) {
    //           const element = report_rab[key];
    //           report_rab[key].nilai_rab = this.formatMoney(element.nilai_rab);
    //           report_rab[key].nilai_pengeluaran = this.formatMoney(
    //             element.nilai_pengeluaran
    //           );
    //           if (element.hasChild == 1 && element.sub_level == "0") {
    //             report_rab[key].is_bold = true;
    //           }
    //         }
    //       }

    //       this.dataReportRab = report_rab;
    //       this.dataHeaderPE = header_pe;

    //       for (const key in report_pe) {
    //         if (Object.hasOwnProperty.call(report_pe, key)) {
    //           const element = report_pe[key];
    //           report_pe[key].nilai = this.formatMoney(element.nilai);
    //           if (element.hasChild == 1 && element.sub_level == "0") {
    //             report_pe[key].is_bold = true;
    //           }
    //         }
    //       }

    //       this.dataReportPE = report_pe;

    //       this.dataHeaderInvoice = header_invoice;
    //       this.dataReportInvoice = report_invoice;

    //       this.titleProject = project.nama;
    //       this.titleDate = moment(project.tanggal_pelaksanaan).format(
    //         "DD MMMM YYYY"
    //       );

    //       this.dataNilai.total_pembayaran = `Rp. ${this.formatMoney(
    //         nilai.total_pembayaran
    //       )}`;

    //       this.dataNilai.nilai_kontrak = `Rp. ${this.formatMoney(
    //         nilai.nilai_kontrak
    //       )}`;

    //       this.dataNilai.total_rab = `Rp. ${this.formatMoney(nilai.total_rab)}`;

    //       this.dataNilai.total_pe = `Rp. ${this.formatMoney(nilai.total_pe)}`;

    //       this.dataNilai.total_pengeluaran = `Rp. ${this.formatMoney(
    //         nilai.total_pengeluaran
    //       )}`;

    //       this.dataNilai.laba_rugi = `Rp. ${this.formatMoney(nilai.laba_rugi)}`;
    //       this.generatePdf();
    //     },
    //     (err) => {
    //       console.log(err);
    //     }
    //   );
    // },

    convertDate(date_start, date_end) {
      var start = moment(date_start);
      var end = moment(date_end);
      if (start.format("YYYY-MM") == end.format("YYYY-MM") && start.isValid()) {
        this.titleDate = `Tgl. ${start.format("DD")} s/d ${end.format(
          "DD MMM YYYY"
        )}`;
      } else if (
        start.format("YYYY") == end.format("YYYY") &&
        start.isValid()
      ) {
        this.titleDate = `Tgl. ${start.format("DD MMM")} s/d ${end.format(
          "DD MMM YYYY"
        )}`;
      } else {
        this.titleDate = `Tgl. ${start.format(
          "DD MMM YYYY"
        )} s/d ${end.format("DD MMM YYYY")}`;
      }
    },

    convertImgToBase64(url) {
      return new Promise((resolve) => {
        var image = new Image();
        image.setAttribute("crossOrigin", "anonymous"); //getting images from external domain

        image.onload = function () {
          var canvas = document.createElement("canvas");
          canvas.width = this.naturalWidth;
          canvas.height = this.naturalHeight;

          //next three lines for white background in case png has a transparent background
          var ctx = canvas.getContext("2d");
          ctx.fillStyle = "#fff"; /// set white fill style
          ctx.fillRect(0, 0, canvas.width, canvas.height);

          canvas.getContext("2d").drawImage(this, 0, 0);

          resolve(canvas.toDataURL("image/jpeg"));
        };

        image.src = url;
      });
    },

    splitTextIntoThreeArrays(text) {
      // Step 1: Split the text into words using whitespace as the delimiter
      const wordsArray = text.split(/\s+/);

      // Step 2: Determine the size of each array
      const totalWords = wordsArray.length;
      const arraySize = Math.ceil(totalWords / 3);

      // Step 3: Divide the words into three arrays
      const firstArray = wordsArray.slice(0, arraySize);
      const secondArray = wordsArray.slice(arraySize, arraySize * 2);
      const thirdArray = wordsArray.slice(arraySize * 2);

      return [firstArray, secondArray, thirdArray];
    },

    async generatePdf() {
      this.isLoading = true;
      const doc = new jsPDF("l", "mm", "a4");
      //   var header = "img/bg-header.png";
      if (this.logo) {
        doc.addImage(this.logo, "png", 7, 20, 16, 16, null, null, 0);
      }
      doc.setFont("Poppins-Bold").setFontSize(12);
      doc.setTextColor(14, 44, 78);
      doc.text(26, 28, `${this.dataCompany.nama}`, {
        align: "left",
      });
      doc.setFont("Poppins-Regular").setFontSize(10);
      doc.setTextColor(137, 137, 137);
      doc.text(26, 34, `${this.dataCompany.email}`, {
        align: "left",
      });

      doc.setFont("Poppins-Regular").setFontSize(10);
      doc.setTextColor(137, 137, 137);
      const [array1, array2, array3] = this.splitTextIntoThreeArrays(
        this.dataCompany.alamat
      );

      doc.text(288, 22, array1.join(" "), {
        align: "right",
      });
      doc.text(288, 28, array2.join(" "), {
        align: "right",
      });
      doc.text(288, 34, array3.join(" "), {
        align: "right",
      });

      var bg = new Image();
      bg.src = "/img/bg-blue.png";
      doc.addImage(bg, "png", 288, 10, 278, 2500 / 72, null, null, 180);

      doc.setTextColor(255, 255, 255);
      doc.setFontSize(20);
      doc.setFont("Poppins-Bold");
      doc.text(17, 57, this.titleReport, {
        align: "left",
      });

      doc.setFontSize(12);
      doc.setFont("Poppins-Regular");
      doc.text(17, 64, this.titleProject, {
        align: "left",
      });

      doc.setFontSize(10);
      doc.text(17, 70, this.titleDate, {
        align: "left",
      });

      doc.setFontSize(12);
      doc.setFont("Poppins-Bold");
      doc.text(279, 56, this.dataClient.nama, {
        align: "right",
      });

      doc.setFontSize(10);
      doc.setFont("Poppins-Regular");
      doc.text(
        279,
        63,
        `${this.dataClient.email} / ${this.dataClient.no_telp}`,
        {
          align: "right",
        }
      );

      doc.setFontSize(10);
      doc.setFont("Poppins-Regular");
      doc.text(279, 70, `${this.dataClient.provinsi}, Indonesia`, {
        align: "right",
      });

      let wantedTableWidth = 150;
      let pageWidth = doc.internal.pageSize.width;
      let aa = (pageWidth - wantedTableWidth) / 2;
      var tableDataSummary = {
        columns: [
          {
            title: "",
            dataKey: "total_pe",
          },
          {
            title: "",
            dataKey: "total_management",
          },
          {
            title: "",
            dataKey: "total_pajak",
          },
        ],
        rows: [this.dataNilai],
      };

      doc.autoTable({
        columns: tableDataSummary.columns,
        body: tableDataSummary.rows,
        showHead: "everyPage",

        didParseCell: function (data) {
          if (data.row.section === "head") {
            data.cell.styles.textColor = "#324C8F";
            if (data.column.dataKey === "nama") {
              data.cell.styles.halign = "left";
            } else {
              data.cell.styles.halign = "right";
            }
          }
          if (data.column.index === 0 && data.row.raw.sub_level) {
            var padding = parseInt(data.row.raw.sub_level)
              ? parseInt(data.row.raw.sub_level) + 1
              : 1;
            data.cell.styles.cellPadding = {
              left: 3 * padding,
              top: 2,
            };
          }

          // console.log(tableData.rows);
        },

        columnStyles: {
          0: {
            cellWidth: 50,
            halign: "center",
          },
          1: {
            cellWidth: 50,
            halign: "center",
          },
          2: {
            cellWidth: 50,
            halign: "center",
          },
        },
        margin: {
          left: aa,
          right: aa,
          top: 2,
        },
        startY: 85,
        theme: "plain",
      });

      var tableText = {
        columns: [
          {
            title: "",
            dataKey: "text_total_pe",
          },
          {
            title: "",
            dataKey: "text_total_management",
          },
          {
            title: "",
            dataKey: "text_total_pajak",
          },
        ],
        rows: [
          {
            text_total_pe: "Total PE",
            text_total_management: "Management Fee",
            text_total_pajak: "PPN",
          },
        ],
      };

      doc.autoTable({
        columns: tableText.columns,
        body: tableText.rows,
        showHead: "everyPage",
        startY: doc.lastAutoTable.finalY - 1, // 10 margin
        didParseCell: function (data) {
          if (data.row.section === "head") {
            data.cell.styles.textColor = "#324C8F";
            if (data.column.dataKey === "nama") {
              data.cell.styles.halign = "center";
            } else {
              data.cell.styles.halign = "center";
            }
          }
          if (data.column.index === 0 && data.row.raw.sub_level) {
            var padding = parseInt(data.row.raw.sub_level)
              ? parseInt(data.row.raw.sub_level) + 1
              : 1;
            data.cell.styles.cellPadding = {
              left: 3 * padding,
              top: 2,
            };
          }

          // console.log(tableData.rows);
        },

        columnStyles: {
          0: {
            cellWidth: 50,
            halign: "center",
            textColor: "#898989",
          },
          1: {
            cellWidth: 50,
            halign: "center",
            textColor: "#898989",
          },
          2: {
            cellWidth: 50,
            halign: "center",
            textColor: "#898989",
          },
        },
        margin: {
          left: aa,
          right: aa,
        },
        theme: "plain",
      });

      var positionY = doc.previousAutoTable.finalY + 5;

      doc.setTextColor(18, 17, 17);
      doc.setFontSize(12);
      doc.setFont("Poppins-Regular");

      var tablePE = {
        columns: this.dataHeaderPE,
        rows: this.dataReportPE,
      };
      doc.autoTable({
        columns: tablePE.columns,
        body: tablePE.rows,
        showHead: "everyPage",
        startY: positionY,
        didParseCell: function (data) {
          if (data.row.section === "head") {
            data.cell.styles.textColor = "#FFF";
            data.cell.styles.fillColor = "#6BACCC";
            if (data.column.dataKey === "nama") {
              data.cell.styles.halign = "left";
            } else {
              data.cell.styles.halign = "right";
            }
          }

          if (data.column.index === 0 && data.row.raw.sub_level) {
            var padding = parseInt(data.row.raw.sub_level)
              ? parseInt(data.row.raw.sub_level) + 1
              : 1;
            data.cell.styles.cellPadding = {
              left: 3 * padding,
              top: 2,
            };
          }
          if (data.column.index == 0 && data.row.raw.hasChild == 1) {
            data.row.cells.nama.colSpan = 6;
          }
          if (data.column.index === 0 && data.row.raw.is_bold) {
            data.cell.styles.fontStyle = "bold";
          }

          // console.log(tableData.rows);
        },

        columnStyles: {
          0: {
            width: 100,
          },
          1: {
            halign: "right",
            width: 60,
          },
          2: {
            halign: "right",
          },
          3: {
            halign: "right",
          },
          4: {
            halign: "right",
          },
          5: {
            halign: "right",
          },
        },
        margin: {
          bottom: 30,
        },
        tableLineWidth: 0, // Adjust the border width as needed
        tableLineColor: [0, 0, 0],
        bodyStyles: {
          lineWidth: 0.1,
          lineColor: [199, 199, 201],
        },
        theme: "plain",
      });

      // PAGE NUMBERING
      // Add Page number at bottom-right
      // Get the number of pages
      const pageCount = doc.internal.getNumberOfPages();
      // var width = doc.internal.pageSize.getWidth();
      // var height = doc.internal.pageSize.getHeight();

      // For each page, print the page number and the total pages
      for (var i = 1; i <= pageCount; i++) {
        doc.setTextColor(255, 255, 255);
        // Go to page i
        doc.setPage(i);

        var img = new Image();
        img.src = "/img/line.png";
        doc.addImage(img, "png", 288, 183, 278, 1 / 72, null, null, 180);
        doc.setFontSize(9);
        doc.setTextColor(0, 0, 0);
        doc.text(105, 257, "Finanta Accounting System Report", "center");

        doc.text(
          "Page " + String(i) + " of " + String(pageCount),
          285,
          210 - 20,
          null,
          null,
          "right"
        );

        doc.text(
          `Tercetak pada ${moment().format("DD MMMM YYYY - HH:mm")}`,
          205 - 192,
          210 - 20,
          null,
          null,
          "left"
        );
      }
      
      this.isLoading = false;

      setTimeout(() => {
        var iframe = document.getElementById("pdfFrame");
        iframe.src = doc.output("bloburi", {
          filename: "ITEM PE.pdf",
        });
      }, 500);
    },

    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    countManagement(){
        var pe = parseInt(this.dataNilai.total_pe.replace("Rp. ", '').replace('.', ''));
        if (this.dataNilai.satuan == "%") {
            var persen = this.dataNilai.management;
            var nilai = Math.ceil((pe*persen)/100) ;
            this.dataNilai.total_management = `Rp. ${this.formatMoney(nilai)}`
        } else {
            this.dataNilai.total_management = `Rp. ${this.formatMoney(this.dataNilai.management)}`
        }
    }
  },

  watch: {
    company: async function(newVal) {
        this.dataCompany = newVal
        if (this.logo == '') {
          this.logo = await this.convertImgToBase64(
            DATA.COMPANY(newVal.logo)
          );
          this.generatePdf();
        }
    },
    client: function(newVal) {
        this.dataClient = newVal
    },
    total: function(newVal) {
        this.dataNilai.total_pe = `Rp. ${this.formatMoney(newVal)}`;
    },
    pajak: function(newVal) {
        this.dataNilai.total_pajak = `Rp. ${this.formatMoney(newVal)}`;
    },
    managementFeeSatuan: function(newVal) {
        this.dataNilai.satuan = newVal;
        this.countManagement();
        if (this.dataReportPE.length > 0) {
         this.generatePdf();
        }
    },
    managementFee: function(newVal) {
        this.dataNilai.management = newVal;
        this.countManagement();
        if (this.dataReportPE.length > 0) {
          this.generatePdf();
        }
    },
    project: function(newVal) {
      this.titleReport = newVal.nama;
      this.titleProject = newVal.lokasi;
      if (newVal.lokasi.length > 70) {
        this.titleProject = newVal.lokasi.substring(0, 70).concat('...');
      }
      this.convertDate(newVal.start, newVal.end);
    },
    list: function(newVal) {
        // this.dataReportPE = newVal;
        // var list = [];
        var list = [...newVal];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            list[key].nilai_rab = this.formatMoney(element.nilai_rab);
            list[key].nilai_pengeluaran = this.formatMoney(
              element.nilai_pengeluaran
            );
            if (element.hasChild == 1 && element.sub_level == "0") {
              list[key].is_bold = true;
            }

            if (element.hasChild == 0) {
                list[key].qty = `${element.qty} ${element.satuan_qty}`
                list[key].frq_qty = `${element.frq_qty} ${element.satuan_frq}`
                list[key].volume = `${element.volume} ${element.satuan_volume}`
                list[key].unit_cost = `Rp. ${this.formatMoney(element.unit_cost)}`
                list[key].nilai = `Rp. ${this.formatMoney(element.nilai)}`
            }
          }
        }
        this.dataReportPE = list;
        this.countManagement();
        this.generatePdf();
    },
  }
};
</script>
    
    <style scoped>
.card-table {
  margin-top: 0px !important;
}

.btn-gaji {
  background-color: #68caf8;
  color: #fff;
  padding: 7px 12px !important;
  font-weight: 400;
  border-radius: 5px;
  font-size: 15px;
  border: none;
}

.btn-bonus {
  background-color: #f8ce3a;
  color: #fff;
  padding: 7px 12px !important;
  font-weight: 400;
  border-radius: 5px;
  font-size: 15px;
  border: none;
}

.btn-thr {
  background-color: #ff5252;
  color: #fff;
  padding: 7px 12px !important;
  font-weight: 400;
  border-radius: 5px;
  font-size: 15px;
  border: none;
}

.dropdown-menu {
  background: transparent !important;
  box-shadow: none !important;
  width: 102px !important;
  min-width: unset;
}

.dropdown-item:nth-child(2) {
  margin-top: 6px;
}

.dropdown-item:nth-child(3) {
  margin-top: 6px;
}

iframe {
  z-index: 1000;
  width: 100%;
  height: 800px;
}
</style>
    
    <style scoped src="../../../../assets/css/style.css"></style>
    
    <style scoped src="../../../../assets/css/custom.css"></style>